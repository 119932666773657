import _ from 'lodash';
import CustomStore from 'devextreme/data/custom_store';
import request from '../../api';
import { fromSecondsToTime } from '../../helpers/toHHMMSS';
import moment from 'moment';


export const newsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/news/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/news', values),
    update: async (key, values) => await request.post('/admin/news/update', { ...values, newsId: key }),
    remove: async (key) => await request.delete('/admin/news', { data: { newsId: key } })
});

export const adminsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { users: data, allCount: totalCount } } = await request.get(`/admins/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/new', _.omit(values, 'code')),
    update: async (key, values) => await request.post('/admin/update', { ..._.omit(values, 'code'), userId: key }),
    remove: async (key) => await request.delete('/user', { data: { userId: key } })
});

export const rolesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/access_control/roles/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/access_control/roles/new', values),
    update: async (key, values) => await request.post('/access_control/roles/update', { ...values, id: key }),
    remove: async (key) => await request.delete('/access_control/role', { data: { id: key } })
});

export const roleResourcesDataGrid = (roleId) => new CustomStore({
    key: 'id',
    load: async () => {
        try {
            const { data } = await request.get(`/access_control/permissions/resources/fullinfo/role/${roleId}`);
            return { data, totalCount: Object.keys(data).length };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/access_control/role/resource', { resourceId: values.resource, roleId }),
    remove: async (key) => await request.delete('/access_control/role/resource', { data: { resourceId: key, roleId } })
});
roleResourcesDataGrid.reset = async (roleId, resources) => await request.post('/access_control/role/resources/permissions/reset', { roleId, resources });


export const certSubscriptionsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { supscriptions: supscriptionsRaw, allCount: totalCount, groupCount } } = await request.get(`/supscriptions/users/cert/fullinfo${params}`);
            const data = Object.keys(supscriptionsRaw).map(k => ({ key: k, items: supscriptionsRaw[k] }));
            return { data, totalCount, groupCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/subscribe', { userId: values.userId, subscriptionPackageId: values.supscriptionPackageId, promotionCode: values.promotionId }),
    update: async (key, values) => await request.post('/admin/subscribe/update', { ...values, userSupscriptionId: key }),
    // remove: async (key) => await request.delete('/supscription/package', { data: { packageId: key } })
});

export const coursesSubscriptionsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { supscriptions: data, allCount: totalCount } } = await request.get(`/supscriptions/users/courses/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/subscribe', { userId: values.userId, subscriptionPackageId: values.supscriptionPackageId, promotionCode: values.promotionId }),
    update: async (key, values) => await request.post('/admin/subscribe/update', { ...values, userSupscriptionId: key }),    // remove: async (key) => await request.delete('/supscription/package', { data: { packageId: key } })
});

export const unitsSubscriptionsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { supscriptions: data, allCount: totalCount } } = await request.get(`/supscriptions/users/level/units/fullinfo${params}`);
            //const data = Object.keys(supscriptionsRaw).map(k => ({ key: k, items: supscriptionsRaw[k] }));
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/subscribe', { userId: values.userId, subscriptionPackageId: values.supscriptionPackageId, promotionCode: values.promotionId }),
    update: async (key, values) => await request.post('/admin/subscribe/update', { ...values, userSupscriptionId: key }),    // remove: async (key) => await request.delete('/supscription/package', { data: { packageId: key } })
});


export const unitsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { units: unitsRaw, allCount: totalCount, groupCount } } = await request.get(`/units/fullinfo${params}`);
            const data = Object.keys(unitsRaw).map(k => ({ key: k, items: unitsRaw[k] }));
            return { data, totalCount, groupCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/unit/new', values),
    update: async (key, values) => await request.post('/unit/update', { ...values, unitId: key }),
    remove: async (key) => await request.delete('/unit', { data: { unitId: key } })
});

export const topicsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { topics: data, allCount: totalCount } } = await request.get(`/topics/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/topic/new', values),
    update: async (key, values) => await request.post('/topic/update', { ...values, topicId: key }),
    remove: async (key) => await request.delete('/topic', { data: { topicId: key } })
});

export const promotionsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { promotions: data, allCount: totalCount } } = await request.get(`/supscription/promotions/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/supscription/promotion/new', values),
    update: async (key, values) => await request.post('/supscription/promotion/update', { ...values, promotionId: key }),
    remove: async (key) => await request.delete('/supscription/promotion', { data: { promotionId: key } })
});


export const certPackagesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { packages: packagesRaw, allCount: totalCount, groupCount } } = await request.get(`/supscription/packages/certificate/fullinfo${params}`);
            const data = Object.keys(packagesRaw).map(k => ({ key: k, items: packagesRaw[k] }));
            return { data, totalCount, groupCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/supscription/package/certificate/new', { ...values, validitySeconds: values.validitySeconds * 60 * 60 * 24 }),
    update: async (key, values) => await request.post('/supscription/package/update', { ...values, validitySeconds: values.validitySeconds * 60 * 60 * 24, packageId: key }),
    remove: async (key) => await request.delete('/supscription/package', { data: { packageId: key } })
});

export const coursesPackagesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { packages: packagesRaw, allCount: totalCount, groupCount } } = await request.get(`/supscription/packages/course/fullinfo${params}`);
            const data = Object.keys(packagesRaw).map(k => ({ key: k, items: packagesRaw[k] }));
            return { data, totalCount, groupCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/supscription/package/course/new', { ...values, validitySeconds: values.validitySeconds * 60 * 60 * 24 }),
    update: async (key, values) => await request.post('/supscription/package/update', { ...values, validitySeconds: values.validitySeconds * 60 * 60 * 24, packageId: key }),
    remove: async (key) => await request.delete('/supscription/package', { data: { packageId: key } })
});

export const unitsPackagesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { packages, allCount: totalCount, groupCount } } = await request.get(`/supscription/packages/units/fullinfo${params}`);
            return { data: packages, totalCount, groupCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/supscription/package/units/new', { ...values, validitySeconds: values.validitySeconds * 60 * 60 * 24 }),
    update: async (key, values) => await request.post('/supscription/package/update', { ...values, validitySeconds: values.validitySeconds * 60 * 60 * 24, packageId: key }),
    remove: async (key) => await request.delete('/supscription/package', { data: { packageId: key } })
});

export const featuresPackagesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { features, allCount: totalCount } } = await request.get(`/supscription/features/fullinfo${params}`);
            return { data: Object.values(features), totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/subscription/feature', values),
    update: async (key, values) => await request.put('/admin/subscription/feature', { ...values, id: key }),
    // remove: async (key) => await request.delete('/supscription/package', { data: { packageId: key } })
});

export const certsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { certificates: data, allCount: totalCount } } = await request.get(`/certificates/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/certificate/new', values),
    update: async (key, values) => await request.post('/certificate/update', { ...values, certificateId: key }),
    remove: async (key) => await request.delete('/certificate', { data: { certificateId: key } })
});

export const coursesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { courses: coursesRaw, allCount: totalCount, groupCount } } = await request.get(`/courses/cert/fullinfo${params}`);
            const data = Object.keys(coursesRaw).map(k => ({ key: k, items: coursesRaw[k] }));
            return { data, totalCount, groupCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/course/new', values),
    update: async (key, values) => await request.post('/course/update', { ...values, courseId: key }),
    remove: async (key) => await request.delete('/course', { data: { courseId: key } })
});
coursesDataGrid.copyCourse = async (courseId) => await request.post('/admin/copy/course', { courseId });

export const oneDayCoursesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { courses: data, allCount: totalCount } } = await request.get(`/courses/oneday/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/course/new', values),
    update: async (key, values) => await request.post('/course/update', { ...values, courseId: key }),
    remove: async (key) => await request.delete('/course', { data: { courseId: key } })
});

export const categoriesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/questions/categories/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/questions/category/new', values),
    update: async (key, values) => await request.post('/questions/category/update', { ...values, id: key }),
    remove: async (key) => await request.delete('/questions/category', { data: { id: key } })
});

export const difficultiesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/questions/difficulties/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/questions/difficulty/new', values),
    update: async (key, values) => await request.post('/questions/difficulty/update', { ...values, id: key }),
    remove: async (key) => await request.delete('/questions/difficulty', { data: { id: key } })
});


export const sizesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/questions/sizes/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/questions/size/new', values),
    update: async (key, values) => await request.post('/questions/size/update', { ...values, id: key }),
    remove: async (key) => await request.delete('/questions/size', { data: { id: key } })
});


export const factsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort', 'courseId', 'unitId', 'topicId']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data: questionsRaw, allCount: totalCount } } = await request.get(`/questions/facts/fullinfo${params}`);
            // const data = Object.keys(questionsRaw).map(k => ({ key: k, items: questionsRaw[k] }));
            return { data: questionsRaw, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/questions/fact/new', values),
    update: async (key, values) => await request.post('/questions/fact/update', { ...values, factId: key }),
    remove: async (key) => await request.delete('/questions/fact', { data: { factId: key } })
});

export const essaysQuestionsDataGrid = (factId) => new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params += `factId=${factId}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount, groupCount } } = await request.get(`/questions/essay/fullinfo${params}`);
            return { data, totalCount, groupCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/questions/question/essay/new', values),
    update: async (key, values) => await request.post('/questions/question/update', { ...values, questionId: key }),
    remove: async (key) => await request.delete('/questions/question', { data: { questionId: key } })
});


export const mcqsQuestionsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort', 'groupSummary', 'courseId', 'unitId', 'topicId']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data: questionsRaw, allCount: totalCount } } = await request.get(`/questions/mcq/fullinfo${params}`);
            // const data = Object.keys(questionsRaw).map((k, indx) => ({ key: k, items: questionsRaw[k], summary: [summary[indx]] }));
            return { data: questionsRaw, totalCount, summary: [totalCount] };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/questions/question/mcq/new', values),
    update: async (key, values) => await request.post('/questions/question/update', { ...values, questionId: key }),
    remove: async (key) => await request.delete('/questions/question', { data: { questionId: key } })
});


export const mcqsQuestionsBatchData = new CustomStore({
    key: 'id',
    insert: async (values) => await request.post('/questions/question/mcq/overall/new', values),
});

export const optionsDataGrid = (questionId) => new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            if (questionId)
                params += `questionId=${questionId}&`;
            params = params.slice(0, -1);
            const { data: { data: raw, allCount } } = await request.get(`/admin/questions/options/fullinfo${params}`);
            const data = raw.map(r => {
                const ob = {
                    ...r,
                    ...r.contents.reduce((obj, item) => Object.assign(obj, { [`${[item.colNameEn]}|${[item.columnId]}|En`]: item.contentEn, [`${[item.colNameAr]}|${[item.columnId]}|Ar`]: item.contentAr }), {})
                };

                // delete ob['contents'];
                return ob;
            });

            return { data, totalCount: allCount || data.length };

        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/questions/option/new', { ...prepareValuesToSend(values), questionId }),
    update: async (key, values) => await request.post('/questions/option/update', { ...prepareValuesToSend(values), optionId: key, questionId }),
    remove: async (key) => await request.delete('/questions/option', { data: { optionId: key } })
});

const prepareValuesToSend = (obj) => {
    let data = {};
    const contents = [];
    Object.keys(obj).map(o => {

        if (o.split('|')[1]) {
            let cc = contents.find(c => c.columnId === o.split('|')[1]);
            if (!cc) {
                cc = { columnId: o.split('|')[1] };
                if (o.split('|')[2] === 'En') cc['contentEn'] = obj[o];
                else if (o.split('|')[2] === 'Ar') cc['contentAr'] = obj[o];
                contents.push(cc);
            } else {
                contents.find(c => c.columnId === o.split('|')[1]).contentEn = o.split('|')[2] === 'En' ? obj[o] : contents.find(c => c.columnId === o.split('|')[1]).contentEn;
                contents.find(c => c.columnId === o.split('|')[1]).contentAr = o.split('|')[2] === 'Ar' ? obj[o] : contents.find(c => c.columnId === o.split('|')[1]).contentAr;
            }
        } else data = { ...data, [o]: obj[o] };

        if (contents.length);
        data = { ...data, contents };
        return data;
    });

    return data;
};


export const textQuestionsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data: questionsRaw, allCount: totalCount, groupCount } } = await request.get(`/questions/text/fullinfo${params}`);
            const data = Object.keys(questionsRaw).map(k => ({ key: k, items: questionsRaw[k] }));
            return { data, totalCount, groupCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/questions/question/text/new', values),
    update: async (key, values) => await request.post('/questions/question/update', { ...values, questionId: key }),
    remove: async (key) => await request.delete('/questions/question', { data: { questionId: key } })
});

export const compositeQuestionsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort', 'groupSummary', 'courseId', 'unitId', 'topicId']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data: questionsRaw, allCount: totalCount } } = await request.get(`/questions/composite/fullinfo${params}`);
            // const data = Object.keys(questionsRaw).map((k, indx) => ({ key: k, items: questionsRaw[k], summary: [summary[indx]] }));
            return { data: questionsRaw, totalCount, summary: [totalCount] };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/questions/question/composite/new', values),
    update: async (key, values) => await request.post('/questions/question/update', { ...values, questionId: key }),
    remove: async (key) => await request.delete('/questions/question', { data: { questionId: key } })
});

export const fileQuestionsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort', 'groupSummary', 'courseId', 'unitId', 'topicId']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data: questionsRaw, allCount: totalCount } } = await request.get(`/questions/afiles/fullinfo${params}`);
            return { data: questionsRaw, totalCount, summary: [totalCount] };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/questions/question/afiles/new', values),
    update: async (key, values) => await request.post('/questions/question/update', { ...values, questionId: key }),
    remove: async (key) => await request.delete('/questions/question', { data: { questionId: key } })
});


export const attachmentsQuestionsDataGrid = (questionId) => new CustomStore({
    key: 'docId',
    load: async () => {
        try {
            const { data: { data: questionsRaw, allCount: totalCount } } = await request.get(`/admin/question/${questionId}/docs/fullinfo`);
            return { data: questionsRaw, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/question/doc', values),
    update: async (key, values) => await request.put('/admin/question/doc', { ...values, questionId, docId: key }),
    remove: async (key) => await request.delete('/admin/question/doc', { data: { docId: key, questionId } })
});

export const usersDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { users: data, allCount: totalCount } } = await request.get(`/students/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/student/new', _.omit(values, 'code')),
    update: async (key, values) => await request.post('/student/update', { ..._.omit(values, 'code'), userId: key }),
    remove: async (key) => await request.delete('/user', { data: { userId: key } })
});

export const instructorsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/instructors/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/instructor', values),
    update: async (key, values) => await request.post('/admin/instructor/update', { ...values, instructorId: key }),
    remove: async (key) => await request.delete('/admin/instructor', { data: { instructorId: key } })
});
instructorsDataGrid.getAssets = async (instructorId) => await request(`/admin/instructor/${instructorId}/distributions`);
instructorsDataGrid.setAssets = async (values) => await request.post('/admin/instructor/distribution/reset', values);

export const successStoriesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/success_stories/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/success_story', values),
    update: async (key, values) => await request.post('/admin/success_story/update', { ...values, successStoryId: key }),
    remove: async (key) => await request.delete('/admin/success_story', { data: { successStoryId: key } })
});
successStoriesDataGrid.getAssets = async (successStoryId) => await request(`/admin/success_story/${successStoryId}/distributions`);
successStoriesDataGrid.setAssets = async (values) => await request.post('/admin/success_story/distribution/reset', values);

export const multiIpUsersDataGrid = new CustomStore({
    key: 'userId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort', 'from', 'to', 'devicesCnt', 'useIps']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/dashboard/users/multi_locations${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const multiIpUserDetailsDataGrid = (userId, useIps) => new CustomStore({
    key: 'firstCreateAt',
    load: async () => {
        try {
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/dashboard/user/${userId}/locations_info?useIps=${useIps}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const paymentsReportDataGrid = new CustomStore({
    key: 'paymentId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            let allDataParams = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);

            ['filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        allDataParams += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            allDataParams = allDataParams.slice(0, -1);


            const promise1 = request.get(`/payments/completed/all${params}`);
            const promise2 = request.get(`/payments/completed/all${allDataParams}`);
            const [{ data: { data, allCount: totalCount } }, { data: { data: allData } }] = await Promise.all([promise1, promise2]);

            return { data, totalCount, summary: [allData.reduce((acc, i) => i.paymentAmountUSD + acc, 0)] };
        } catch (err) {
            return err;
        }
    },

    update: async (key, values) => await request.post('/admin/payment/update', { ...values, paymentId: key }),
});

export const ManualPaymentsDataGrid = new CustomStore({
    key: 'paymentId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            let allDataParams = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);

            ['filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        allDataParams += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            allDataParams = allDataParams.slice(0, -1);


            const promise1 = request.get(`/admin/payments/manual${params}`);
            const promise2 = request.get(`/admin/payments/manual${allDataParams}`);
            const [{ data: { data, allCount: totalCount } }, { data: { data: allData } }] = await Promise.all([promise1, promise2]);

            return { data, totalCount, summary: [allData.reduce((acc, i) => i.amountUSD + acc, 0)] };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/payment', values),
    update: async (key, values) => await request.post('/admin/payment/update', { ...values, paymentId: key }),
    remove: async (key) => await request.delete('/payment', { data: { paymentId: key } })
});


export const examsDataGrid = new CustomStore({
    key: 'examId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['filter', 'group', 'skip', 'sort', 'take', 'requireGroupCount', 'requireTotalCount']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params += 'types=FREQ_PARENT,FREQ&';
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount, groupCount } } = await request.get(`/admin/exams/fullinfo${params}`);
            return { data, totalCount, groupCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/exam', { ...values, explainVideoId: values.explainVideoUrl, durationSeconds: values.durationSeconds * 60 }),
    update: async (key, values) => await request.post('/admin/exam/update', { ...values, explainVideoId: values.explainVideoUrl, durationSeconds: values.durationSeconds * 60, examId: key }),
    remove: async (key) => await request.delete('/admin/exam', { data: { examId: key } })
});
examsDataGrid.copyParent = async (examId) => await request.post('/admin/copy/exam', { examId });


export const examsQuestionsDistributionDataGrid = (examId) => new CustomStore({
    key: 'examQuestionDistId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/exam/${examId}/questions/dist${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/exam/questions/dist', values),
    update: async (key, values) => await request.put('/admin/exam/questions/dist', { ...values, examQuestionDistId: key }),
    remove: async (key) => await request.delete('/admin/exam/questions/dist', { data: { examQuestionDistIds: key } })
});
examsQuestionsDistributionDataGrid.addBatch = async (data) => await request.post('/admin/exam/questions/dist', data);
examsQuestionsDistributionDataGrid.deleteBatch = async (data) => await request.delete('/admin/exam/questions/dist', { data });


export const examsCorrectionDataGrid = (courseId, questionsType, examTypes, date) => new CustomStore({
    key: 'examRoundId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params += `groupby=${false}&`;
            params += `courseId=${courseId}&`;
            params += `mustBeFinished=${true}&`;
            params += `examQuestionsTypes=${questionsType?.join(',')}&`;
            params += `examTypes=${examTypes?.join(',')}&`;
            if (date?.[0]) params += `startDateFrom=${moment(date[0]).locale("en").format("YYYY-MM-DD")}&`;
            if (date?.[1]) params += `startDateTo=${moment(date[1]).locale("en").format("YYYY-MM-DD")}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/exams/rounds/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/exam', { ...values, explainVideoId: values.explainVideoUrl, durationSeconds: values.durationSeconds * 60 }),
    update: async (key, values) => await request.post('/admin/exam/update', { ...values, explainVideoId: values.explainVideoUrl, durationSeconds: values.durationSeconds * 60, examId: key }),
    remove: async (key) => await request.delete('/admin/exam', { data: { examId: key } })
});

export const coursesCalendarDataGrid = new CustomStore({
    key: 'calendarId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });

            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/calendar/courses/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/calendar/course', values),
    update: async (key, values) => await request.post('/admin/calendar/course/update', { ...values, calendarId: key }),
    remove: async (key) => await request.delete('/admin/calendar/courses', { data: { calendarId: key } })
});

export const reportsDataGrid = (type) => new CustomStore({
    key: 'issueReportId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });

            if (type) params += `reportOn=${type}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/issues_reports/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    // insert: async (values) => await request.post('/admin/calendar/course', values),
    update: async (key, values) => await request.post('/admin/issue_report/update', { ...values, issueReportId: key }),
    remove: async (key) => await request.delete('/admin/issue_report', { data: { issueReportId: key } })
});

export const settingsDataGrid = (category) => new CustomStore({
    key: 'idSetting',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });

            if (category) params += `category=${category}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/settings/fullinfo${params}`);
            return { data: data.map(i => ({ ...i, isActive: i.value[0], isImportant: i.value[1], useEmail: i.value[2] })), totalCount };
        } catch (err) {
            return err;
        }
    },
    update: async (key, values) => await request.post('/admin/settings/update', { idSetting: key, value: `${values.isActive ? '1' : '0'}${values.isImportant ? '1' : '0'}${values.useEmail ? '1' : '0'}` }),
});


export const honorRollDataGrid = new CustomStore({
    key: 'honorId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/honors${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/honor', values),
    update: async (key, values) => await request.post('/admin/honor/update', { ...values, honorId: key }),
    remove: async (key) => await request.delete('/admin/honor', { data: { honorId: key } })
});


export const userVideoComments = (videoId) => new CustomStore({
    key: 'videoNoteId',
    load: async () => {
        try {
            const { data: { data } } = await request.get(`/student/video-notes/${videoId}`);
            return data;
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post(`/student/video-note/${videoId}`, values),
    update: async (key, values) => await request.post('/student/video-note/update', { ...values, videoNoteId: key }),
    remove: async (key) => await request.delete('/student/video-note', { data: { videoNoteId: key } })
});

export const videoSegmentsDataGrid = (videoId) => new CustomStore({
    key: 'id',
    load: async () => {
        try {
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/videos/segments?videoId=${videoId}`);
            return {
                data: data.map(d => {
                    const [fromH, fromM, fromS] = fromSecondsToTime(d.fromSecond);
                    const [toH, toM, toS] = fromSecondsToTime(d.toSecond);
                    return { ...d, fromH, fromM, fromS, toH, toM, toS };
                }), totalCount
            };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/video/segment', {
        ...values, videoId,
        fromSecond: (values.fromS || 0) + ((values.fromM || 0) * 60) + ((values.fromH || 0) * 3600),
        toSecond: (values.toS || 0) + ((values.toM || 0) * 60) + ((values.toH || 0) * 3600),
    }),
    update: async (key, values) => await request.put('/admin/video/segment', { ...values, id: key, videoId }),
    remove: async (key) => await request.delete('/video/segment', { data: { id: key } })
});

export const teamMembersDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/team-member${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/team-member', values),
    update: async (key, values) => await request.post('/admin/team-member/update', { ...values, id: key }),
    remove: async (key) => await request.delete('/admin/team-member', { data: { id: key } })
});

export const examLinksDataGrid = (examId) => new CustomStore({
    key: 'examLinkId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            if (examId) params += `examId=${examId}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/exam/links${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/exam/link', values),
    update: async (key, values) => await request.put('/admin/exam/link', { ...values, examLinkId: key }),
    remove: async (key) => await request.delete('/admin/exam/link', { data: { examLinkId: key } })
});

export const blogCategoriesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/categories/blog${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/category/blog', values),
    update: async (key, values) => await request.put('/admin/category/blog', { ...values, id: key }),
    remove: async (key) => await request.delete('/admin/category/blog', { data: { id: key } })
});

export const blogTagsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/tags${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/tag', values),
    update: async (key, values) => await request.put('/admin/tag', { ...values, id: key }),
    remove: async (key) => await request.delete('/admin/tag', { data: { id: key } })
});

export const blogsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/blogs${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/blog', { ...values, contentJson: values?.contentJson ? values.contentJson : JSON.stringify({ blocks: [], settings: {} }) }),
    update: async (key, values) => await request.put('/admin/blog', { ...values, id: key }),
    remove: async (key) => await request.delete('/admin/blog', { data: { id: key } })
});

export const FAQsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/faqs${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/faq', values),
    update: async (key, values) => await request.post('/admin/faq/update', { ...values, id: key }),
    remove: async (key) => await request.delete('/admin/faq', { data: { id: key } })
});


export const uploadCoursesVideosDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort', 'courseId', 'unitId', 'topicId']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/videos/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/video/new', values),
    update: async (key, values) => await request.put('/video/update', { ...values, videoId: key }),
    remove: async (key) => await request.delete('/video/topic', { data: { videoId: key } })
});

export const uploadCoursesBooksDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort', 'courseId', 'unitId', 'topicId']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/books/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/book', values),
    update: async (key, values) => await request.put('/admin/book', { ...values, bookId: key }),
    remove: async (key) => await request.delete('/admin/book/topic', { data: { bookId: key } })
});

export const packagePoliciesDataGrid = new CustomStore({
    key: 'policyId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/policy${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/policy', values),
    update: async (key, values) => await request.put('/admin/policy', { ...values, policyId: key }),
    remove: async (key) => await request.delete('/admin/policy', { data: { policyId: key } })
});


export const usersPerformancesDataGrid = (courseId, topicsIds, date) => new CustomStore({
    key: 'email',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });

            if (topicsIds?.length) params += `topicsIds=${topicsIds.join(',')}&`;
            if (date?.[0]) params += `fromDate=${moment(date[0]).locale("en").format("YYYY-MM-DD")}&`;
            if (date?.[1]) params += `toDate=${moment(date[1]).locale("en").format("YYYY-MM-DD")}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/performance/users/course/${courseId}${params}`);
            return {
                data: data.map(p => {
                    try {
                        const perofrmanceColumns = JSON.parse(p.performance);
                        return { ...p, ...perofrmanceColumns };
                    } catch (error) {
                        return {};
                    }
                })
                , totalCount
            };
        } catch (err) {
            return err;
        }
    }
});

export const userPerformanceNotesDataGrid = (userId, courseId) => new CustomStore({
    key: 'userNoteId',
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'group', 'filter', 'sort']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            if (courseId) params += `courseId=${courseId}&`;
            if (userId) params += `userId=${userId}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/user/note${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/user/note', values),
    update: async (key, values) => await request.put('/admin/user/note', { ...values, userNoteId: key }),
    remove: async (key) => await request.delete('/admin/user/note', { data: { userNoteId: key } })
});
