import { lazy } from 'react';

// LANDING SECTION
// Auths
const Signup = lazy(() => import('./landing section/Auths/Signup'));
const Signin = lazy(() => import('./landing section/Auths/Signin'));
const ForgotPassword = lazy(() => import('./landing section/Auths/ForgotPassword'));
const ResetPassword = lazy(() => import('./landing section/Auths/ResetPassword'));
const Activation = lazy(() => import('./landing section/Auths/Activation'));
const Profile = lazy(() => import('./landing section/Auths/Profile'));

const LandginHome = lazy(() => import('./landing section/Home'));
const LandingCerts = lazy(() => import('./landing section/Certs'));
const LandingCourses = lazy(() => import('./landing section/Courses'));
const LandingCertificationDetails = lazy(() => import('./landing section/CertificateDetails'));
const LandingCourseDetails = lazy(() => import('./landing section/CourseDetails'));
const LandingStaff = lazy(() => import('./landing section/Staff'));
const LandingContactUs = lazy(() => import('./landing section/ContactUs'));
const LandingWhyUs = lazy(() => import('./landing section/WhyUs'));
const TermsAndConditions = lazy(() => import('./landing section/TermAndConditions'));
const PrivacyPolicy = lazy(() => import('./landing section/PrivacyPolicy'));
const LandingHonor = lazy(() => import('./landing section/Honor'));
const LandingBlogs = lazy(() => import('./landing section/Blogs'));
const LandingBlogDetails = lazy(() => import('./landing section/BlogDetails'));

// USER SECTION
const UserDashboard = lazy(() => import('./user section/Dashboard'));
const UserSubsctiptions = lazy(() => import('./user section/Subscriptions'));
const UserCourseDetails = lazy(() => import('./user section/CourseDetails'));
const UserCourseStudy = lazy(() => import('./user section/CourseStudy'));
const UserPerformance = lazy(() => import('./user section/Performance'));
const UserMcqsBank = lazy(() => import('./user section/McqsBank'));
const UserEssaysBank = lazy(() => import('./user section/EssaysBank'));
const UserTbsBank = lazy(() => import('./user section/TbsBank'));
const UserFileBank = lazy(() => import('./user section/FileBank'));
const UserMockExam = lazy(() => import('./user section/MockExam'));
const UserExam = lazy(() => import('./user section/ExamPage'));
const UserFreqExam = lazy(() => import('./user section/FreqExamPage'));
const UserExamsLog = lazy(() => import('./user section/ExamsLog'));
const UserExamCorrection = lazy(() => import('./user section/ExamCorrection'));
const UserExamResutls = lazy(() => import('./user section/ExamResults'));
const UserSupport = lazy(() => import('./user section/Support'));
const UserNotifications = lazy(() => import('./user section/Notifications'));
const UserCalendar = lazy(() => import('./user section/Calendar'));

// ADMIN SECTION
// Roles
const AdminRoles = lazy(() => import('./admin section/Roles/Roles'));
// Program
const AdminCerts = lazy(() => import('./admin section/Program/Cerifications'));
const AdminCourses = lazy(() => import('./admin section/Program/Courses'));
const AdminOneDayCourses = lazy(() => import('./admin section/Program/OneDayCourses'));
const AdminUnits = lazy(() => import('./admin section/Program/Units'));
const AdminTopics = lazy(() => import('./admin section/Program/Topics'));
const AdminUploadVideos = lazy(() => import('./admin section/Program/UploadVideos'));
const AdminUploadBooks = lazy(() => import('./admin section/Program/UploadBooks'));

// Questions
const AdminQuestionsMcqsNew = lazy(() => import('./admin section/Questions/Mcqs/QuestionsMcqsNew'));
const AdminQuestionsEssay = lazy(() => import('./admin section/Questions/Essay/QuestionsEssay'));
const AdminQuestionsMcqs = lazy(() => import('./admin section/Questions/Mcqs/QuestionsMcqs'));
const AdminQuestionsText = lazy(() => import('./admin section/Questions/Text/QuestionsText'));
const AdminQuestionsComposite = lazy(() => import('./admin section/Questions/Composite'));
const AdminQuestionsFile = lazy(() => import('./admin section/Questions/File'));
const AdminExplanationsList = lazy(() => import('./admin section/Questions/QuestionsExplanationsList'));
const AdminSizes = lazy(() => import('./admin section/Questions/Sizes'));
const AdminCategories = lazy(() => import('./admin section/Questions/Categories'));
const AdminDifficulties = lazy(() => import('./admin section/Questions/Difficulties'));
// Packages
const AdminPackages = lazy(() => import('./admin section/Packages/Packages'));
const AdminPackagesPolicies = lazy(() => import('./admin section/Packages/Policies'));

// Subscriptions
const AdminSubscriptions = lazy(() => import('./admin section/Subscriptions/Subscriptions'));
const AdminPromotions = lazy(() => import('./admin section/Subscriptions/Promotions'));
// Stakeholders
const AdminAdmins = lazy(() => import('./admin section/Stakeholders/Admins'));
const AdminUsers = lazy(() => import('./admin section/Stakeholders/Users'));
// Generals
const AdminDashboard = lazy(() => import('./admin section/Dashboard'));
const AdminNews = lazy(() => import('./admin section/News'));
// Instructors
const AdminInstructors = lazy(() => import('./admin section/Instructors/Instructors'));
// Team Members
const AdminTeamMembers = lazy(() => import('./admin section/TeamMembers/TeamMembers'));
// FAQs
const AdminFAQs = lazy(() => import('./admin section/FAQs/FAQs'));
// Success Stories
const AdminSuccessStories = lazy(() => import('./admin section/SuccessStories/SuccessStories'));
// Reports
const AdminPaymentsReport = lazy(() => import('./admin section/Reports/PaymentsReport'));
const AdminManualPayments = lazy(() => import('./admin section/Reports/ManualPayments'));
const AdminContentReports = lazy(() => import('./admin section/Reports/ContentReports'));
const AdminUserPerformance = lazy(() => import('./admin section/Reports/Performance/UserPerformance'));
const AdminUsersPerformances = lazy(() => import('./admin section/Reports/Performance/UsersPerformances'));
// Exams
const AdminMockExams = lazy(() => import('./admin section/Exams/MockExamsManagement'));
const AdminExams = lazy(() => import('./admin section/Exams/ExamsManagement'));

// Exam Correction
const AdminExamCorrection = lazy(() => import('./admin section/Exams/ExamCorrection'));
// Courses Calendar
const AdminCoursesCalendar = lazy(() => import('./admin section/Calendar'));
// Messages
const AdminMessages = lazy(() => import('./admin section/Messages'));
// Settings
const AdminIpLogs = lazy(() => import('./admin section/Settings/IpLogSettings/IpLogs'));
const AdminNotifications = lazy(() => import('./admin section/Settings/NotificationsSettings'));
const AdminPolicyTermsSettings = lazy(() => import('./admin section/Settings/AdminPolicyTermsSettings'));
// HONOR ROLL 
const AdminHonorRoll = lazy(() => import('./admin section/HonorRoll'));
// Blogs
const AdminBlogs = lazy(() => import('./admin section/Blogs'));
const AdminBlogsCategories = lazy(() => import('./admin section/Blogs/Categories'));
const AdminBlogsTags = lazy(() => import('./admin section/Blogs/Tags'));

export {
    LandginHome, LandingCerts, LandingCourses, LandingCertificationDetails, LandingCourseDetails, LandingStaff, LandingContactUs, LandingWhyUs,
    Signup, Signin, ForgotPassword, ResetPassword, Profile, Activation, TermsAndConditions, PrivacyPolicy, LandingHonor,
    LandingBlogs, LandingBlogDetails,

    UserDashboard, UserCourseStudy, UserCourseDetails, UserSubsctiptions, UserPerformance,
    UserMcqsBank, UserEssaysBank, UserTbsBank, UserFileBank,
    UserMockExam, UserExam, UserFreqExam, UserExamsLog, UserSupport, UserNotifications, UserCalendar,
    UserExamCorrection, UserExamResutls,

    AdminDashboard, AdminAdmins, AdminUsers, AdminSubscriptions, AdminCerts, AdminCourses, AdminUnits, AdminTopics,
    AdminQuestionsMcqsNew, AdminSizes, AdminCategories, AdminDifficulties, AdminOneDayCourses, AdminPackages,
    AdminRoles, AdminPromotions, AdminQuestionsEssay, AdminQuestionsMcqs, AdminQuestionsText, AdminExplanationsList, AdminQuestionsComposite,
    AdminQuestionsFile, AdminUploadVideos, AdminUploadBooks,
    AdminNews, AdminInstructors, AdminSuccessStories,
    AdminPaymentsReport, AdminManualPayments, AdminContentReports, AdminUserPerformance, AdminUsersPerformances,
    AdminIpLogs, AdminExams, AdminMockExams, AdminExamCorrection, AdminCoursesCalendar, AdminMessages, AdminNotifications,
    AdminHonorRoll, AdminPolicyTermsSettings, AdminTeamMembers, AdminPackagesPolicies,
    AdminBlogs, AdminBlogsCategories, AdminBlogsTags, AdminFAQs
};